<template>
    <div>
        <form @submit.prevent="">
            <div class="form-box">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="d-flex justify-content-between align-items-center">
                                <label for="job-title">{{ $t("Job Title") }}*</label>
                                <div class="form-note mt-0" style="margin-bottom: 10px!important;">
                                    <a href="javascript:void(0);" class="text-capitalize color-primary"
                                       @click.prevent="showJobPostTemplates"> {{ $t('Select a template') }}</a>
                                </div>
                            </div>

                            <input type="text" id="job-title" ref="job_title" v-model="form.title"
                                   :placeholder="$t('enter your job title')" class="form-control" maxlength="150"
                                   autofocus/>
                            <div class="form-note">
                                <span class="text-capitalize">{{
                                        $t("tips")
                                    }}: </span>{{
                                $t("the job title is essential for your job post to attract the best candidates.")
                                }} {{ $t("(Example: Senior Executive)") }}
                            </div>
                            <error-message :message="$t(errors.title)"/>
                        </div>
                    </div>

                    <div class="col-md-12">

                        <ul class="nav nav-pills mb-3" v-if="jobDetailsTabs.length">
                            <li class="nav-item" v-for="tab in jobDetailsTabs">
                                <a class="nav-link" :class="{'active': tab.id === activeTabId}" href="#"
                                   @click.prevent="switchTab(tab)">
                                    {{ $t(tab.title) }}{{ tab.id === 1 ? '*' : '' }}
                                </a>
                            </li>
                        </ul>

                        <div class="form-group" v-show="activeTabId===1">
                            <div class="editor__wrapper ej-ck-editor" id="job-details">
                                <ckeditor :editor="editor" :config="jobDetailEditorConfig" v-model="form.details"
                                          @ready="onJDReady"></ckeditor>
                            </div>
                            <error-message v-show="activeTabId===1" :message="$t(errors.details)"/>
                        </div>
                        <div class="clearfix"></div>

                        <div class="form-group" v-show="activeTabId===2">
                            <div class="editor__wrapper ej-ck-editor" id="job-responsibilities">
                                <ckeditor :editor="editor" :config="jobResponsibilityEditorConfig" v-model="form.responsibilities"
                                          @ready="onJRReady"></ckeditor>
                            </div>
                            <error-message v-show="activeTabId===2" :message="$t(errors.responsibilities)"/>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="d-flex justify-content-between">
                                <label>{{ $t("Category") }}*</label>
                                <div class="form-note mt-0">
                                    <router-link :to="{name: 'company.setting.category-skill'}" target="_blank"><span
                                            style="color:#597dfc;">{{ $t(`Click here`) }}</span></router-link>
                                    {{ $t("to manage your own categories.") }}
                                </div>
                            </div>

                            <multiselect
                                    name="category"
                                    v-model="form.category"
                                    :options="categoryOptions"
                                    :placeholder="$t('Select Category')"
                                    @open="findCategory"
                                    @search-change="getCategories"
                                    track-by="id"
                                    label="name"
                                    group-values="opts"
                                    group-label="group_name"
                            ></multiselect>
                            <error-message :message="$t(errors.category)"/>
                            <error-message :message="$t(errors['category.id'])"/>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="vacancies">{{ $t("Vacancies") }}</label>
                            <input type="number" v-model="form.vacancies" :placeholder="$t('No of Vacancies')"
                                   class="form-control" id="vacancies" min="1" max="9999"/>
                            <error-message :message="$t(errors.vacancies)"/>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="vacancies">{{ $t("Allow Remote") }}</label>
                            <div class="custom-control custom-switch my-2">
                                <input type="checkbox" id="allow___remote" class="custom-control-input"
                                       v-model="form.is_remote" value="true">
                                <label for="allow___remote"
                                       class="custom-control-label">{{ form.is_remote ? $t("Yes") : $t("No") }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t("Country") }}</label>

                            <multiselect
                                    id="country"
                                    v-model="form.country"
                                    :options="countryOptions"
                                    :placeholder="$t('Select Country')"
                                    @open="findCountry"
                                    track-by="id"
                                    label="name"
                                    :class="{'disabled' : form.is_remote}"
                            ></multiselect>
                            <error-message :message="$t(errors.country)"/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t("State") }}</label>

                            <multiselect
                                    id="state"
                                    v-model="form.state"
                                    :options="stateOptions"
                                    :placeholder="$t('Select State')"
                                    @open="findState"
                                    track-by="id"
                                    label="name"
                                    :class="{'disabled' : form.is_remote}"
                            ></multiselect>
                            <error-message :message="$t(errors.state)"/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t("City") }}</label>

                            <multiselect
                                    id="city"
                                    v-model="form.city"
                                    :options="cityOptions"
                                    :placeholder="$t('Select City')"
                                    @open="findCity"
                                    track-by="id"
                                    label="name"
                                    :class="{'disabled' : form.is_remote}"
                            ></multiselect>
                            <error-message :message="$t(errors.city)"/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t("Expiry Date") }}*</label>
                            <div class="date-picker">
                                <date-picker
                                        id="expire_date"
                                        v-model="form.expire_at"
                                        :placeholder="$t('mm/dd/yyyy')"
                                        autocomplete="off"
                                        :config="datePickerOptions"
                                ></date-picker>
                            </div>
                            <error-message :message="$t(errors.expire_at)"/>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group mb-0">
                            <div class="d-flex justify-content-between">
                                <label>{{ $t("Add Skills") }}*</label>
                                <div class="form-note mt-0">
                                    <router-link :to="{name: 'company.setting.category-skill'}" target="_blank"><span
                                            style="color:#597dfc;">{{ $t(`Click here`) }}</span></router-link>
                                    {{ $t("to manage your own skills.") }}
                                </div>
                            </div>
                            <multiselect
                                    id="skills"
                                    v-model="form.skills"
                                    :options="skillsOptions"
                                    :placeholder="$t('Select Skills')"
                                    @open="findSkills"
                                    :multiple="true"
                                    track-by="id"
                                    label="name"
                                    group-values="opts"
                                    group-label="group_name"
                            ></multiselect>
                            <error-message :message="$t(errors.skills)"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-box mt-30">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t("Employment Type") }}*</label>
                            <div class="d-flex align-content-between">
                                <multiselect
                                        id="employment_type"
                                        v-model="form.employment_type"
                                        :options="employmentTypeOptions"
                                        :placeholder="$t('select employment type')"
                                        track-by="id"
                                        @select="showOtherOption"
                                        :allowEmpty="false"
                                        deselectLabel=""
                                        label="name"
                                ></multiselect>
                                <input type="text" v-show="otherEmploymentType"
                                       v-model.trim="employment_type_other"
                                       :placeholder="$t('Employment type')"
                                       class="form-control ml-2"
                                       maxlength="50"
                                       id="custom_emp_type">
                            </div>

                            <error-message :message="$t(errors.employment_type)"/>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="text-capitalize">{{ $t("experiences level") }}</label>

                            <multiselect
                                    id="experience_level"
                                    v-model="form.experience_level"
                                    :options="experienceLevelOptions"
                                    :placeholder="$t('select experiences level')"
                                    track-by="id"
                                    label="name"
                            ></multiselect>
                            <error-message :message="$t(errors.experience_level)"/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t("Salary Type") }}*</label>

                            <multiselect
                                    id="salary_type"
                                    v-model="form.salary_type"
                                    :options="salaryTypeOptions"
                                    :placeholder="$t('Select Salary Type')"
                                    track-by="id"
                                    label="name"
                            ></multiselect>
                            <error-message :message="$t(errors.salary_type)"/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="salary">{{ $t("Salary") }}</label>
                            <input type="text" id="salary" v-model="form.salary" :placeholder="$t('write salary here')"
                                   class="form-control" maxlength="50"/>
                            <error-message :message="$t(errors.salary)"/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t("Office Time") }}</label>
                            <input type="text" v-model="form.office_time" class="datepicker form-control"
                                   :placeholder="$t('Office Time')" maxlength="200"/>
                            <div class="form-note mt-2">
                                <span>{{ $t("hint") }}: </span>
                                {{ $t("type your office time like [SUN - THU: 8:00 AM to 6:00 PM]") }}
                            </div>
                            <error-message :message="$t(errors.office_time)"/>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t("Show Company Benefits?") }}</label>
                            <v-popover offset="10" :disabled="false" style="display: initial">
                                <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                                <template slot="popover">
                                    <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                    <a v-close-popover
                                       href="https://easy.jobs/docs/how-to-show-company-benefits-in-a-job-post/#1-toc-title"
                                       target="_blank">
                                        {{ $t(`How to show company benefits?`) }}
                                    </a>
                                </template>
                            </v-popover>
                            <div class="d-flex align-items-center">
                                <div class="custom-control custom-switch my-2" style="min-width: 100px;">
                                    <input type="checkbox" id="has-benefits" class="custom-control-input"
                                           v-model="form.has_benefits" value="true">
                                    <label for="has-benefits" class="custom-control-label">{{
                                        form.has_benefits ? $t("Yes") : $t("No")
                                        }}</label>
                                </div>

                                <div class="form-note ml-4" v-if="!company.hasBenefits">
                                    <span class="text-capitalize">{{ $t("note") }}: </span>
                                    {{ $t("Company Benefits are not found.") }} <br/>
                                    <router-link :to="{name:'company.setting.basic', hash:'#benefits'}" target="_blank"
                                                 class="text-capitalize color-primary">{{ $t('click here') }}
                                    </router-link>
                                    {{ $t('to setup Company Benefits.') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-box mt-30">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{{ $t("Banner Image") }}</label>
                            <div class="thumbnail-upload" :style="`background-image: url('${coverPhoto}');`">
                                    <span class="company-powered_by" id="remove-company-cover-photo">
                                        <span class="selection-box">
                                            <input type="checkbox" class="notify"
                                                   v-model="form.hideCoverPhoto"
                                                   value="true"
                                                   id="remove-cover-photo"
                                            />
                                            <label for="remove-cover-photo" class="power-by__remove">
                                                <span style="text-transform: none;" v-if="!form.hideCoverPhoto">{{ $t('Show') }}</span>
                                                <span style="text-transform: none;" v-else>{{ $t('Hide') }}</span>
                                            </label>
                                        </span>
                                    </span>

                                <label class="file-browse-button">
                                    <span>{{ $t("Update Banner Image") }}</span>
                                    <input
                                            id="cover_photo"
                                            type="file"
                                            class="myfile"
                                            ref="cover_photo"
                                            accept=".png, .jpg, .jpeg"
                                            @change="setCroppieConfig(600, 315, 1600, 840); croppie('cover_photo', $event)"
                                    />
                                </label>
                            </div>
                            <div class="form-note">
                                <span class="text-capitalize">{{ $t("tips") }}: </span>
                                {{ $t("for better view upload image more then (1600x840)px.") }}
                                {{ $t("company cover image will be used if not uploaded.") }}
                            </div>
                            <error-message :message="$t(errors.file)"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-gap d-flex justify-content-between">
                <div></div>
                <submit-button :click="saveJob" :loading="isLoading" v-if="!editMode">{{
                    $t("Save and Continue")
                    }}
                </submit-button>
                <submit-button :click="updateJob" :loading="isLoading" v-if="editMode">{{
                    $t("Save and Continue")
                    }}
                </submit-button>
            </div>
        </form>
        <div v-if="showCropperModel" class="modal vue-cropper show" id="vue-cropper-modal" style="display: block">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content" v-on-click-away="closeCropperModal">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t("Resize Image") }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" @click="closeCropperModal">&times;</span>
                        </button>
                    </div>
                    <div id="image-crop-wrapper" class="modal-body croppie-container ready">
                        <vue-croppie
                                ref="croppieRef"
                                :enableOrientation="true"
                                :enableResize="false"
                                :boundary="croppieShowOption.boundary"
                                :viewport="croppieShowOption.viewport">
                        </vue-croppie>
                    </div>

                    <div class="modal-footer flex justify-content-between">
                        <button type="button" class="button warning-button" @click="closeCropperModal">{{ $t(`Close`) }}
                        </button>
                        <button type="button" @click.prevent="cropImage()" class="button success-button"
                                :class="{'disabled' : uploadingImage}">
                            <span v-if="uploadingImage">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                     class="mr-2" style="margin: auto; shape-rendering: auto;" width="20px"
                                     height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <circle cx="50" cy="50" r="24" stroke-width="8" stroke="#fff"
                                            stroke-dasharray="37.69911184307752 37.69911184307752" fill="none"
                                            stroke-linecap="round" transform="rotate(300.791 50 50)">
                                        <animateTransform attributeName="transform" type="rotate"
                                                          repeatCount="indefinite" dur="1.01010101010101s"
                                                          keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                                    </circle>
                                </svg>
                                {{ $t("please wait") }}
                            </span>
                            <template v-else>{{ $t(`Save`) }}</template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showCropperModel" class="modal-backdrop fade show"></div>
        <job-template-search-modal v-if="showJobTemplateSearch" @closeJobTemplateModal="showJobTemplateSearch=false"
                                   @templateSelected="selectedTemplate"></job-template-search-modal>
        <modal-job-preview
                :preview-url="previewUrl"
                v-if="showJobTempPreview"
                @closeModal="hideJobPreview"
        />
    </div>
</template>
<script>

import Vue from 'vue';
import client from '../../app/api/Client';
import Multiselect from 'vue-multiselect';
import {
    getCategory,
    getCity,
    getCountry,
    getJobBasicData,
    getJobCreateMetaData,
    getSkill,
    getState
} from "../../app/api/CommonRequest";
import {JOB} from "../../extra/constants";
import {EventBus} from "../../extra/event-bus";
import {CHECK_AND_PUBLISH_JOB, SHOW_JOB_TEMP_PREVIEW, UPDATE_JOB_INFO} from "../../constants/events";
import {mapActions, mapState} from "vuex";
import moment from "moment";
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import {ckEditorBasicOptions, datePickerIcons} from "../../config/options";
import _, {debounce} from "lodash";
import VueCroppie from "vue-croppie";
import 'croppie/croppie.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import {canPublishJob} from "../../config/permission";
import {LOAD_MESSAGE_NOTIFICATION} from "../../constants/action-type";
const JobTemplateSearchModal = () => import('../../components/modal/job/SearchJobTemplate');
const ErrorMessage = () => import("../../components/common/ErrorMessage");
const ModalJobPreview = () => import("../../components/modal/job/Preview")

Vue.use(VueCroppie);


export default {
    components: {
        ErrorMessage,
        Multiselect,
        datePicker,
        JobTemplateSearchModal,
        ModalJobPreview,
        ckeditor: CKEditor.component
    },
    data() {
        return {
            editor: DecoupledEditor,
            editMode: false,
            currentJob: null,
            form: {
                hideCoverPhoto: false,
                coverphoto: '',
                title: '',
                details: '',
                responsibilities: '',
                category: '',
                vacancies: null,
                is_remote: false,
                country: '',
                state: '',
                city: '',
                expire_at: '',
                employment_type:  null,
                experience_level: '',
                salary_type: '',
                salary: null,
                office_time: '',
                skills: [],
                benefits: [],
                has_benefits: false,
                employment_type_other: null
            },
            errors: {
                coverPhoto: '',
                title: '',
                details: '',
                responsibilities: '',
                category: '',
                'category.id': '',
                vacancies: null,
                is_remote: false,
                country: '',
                state: '',
                city: '',
                expire_at: '',
                employment_type: '',
                experience_level: '',
                salary_type: '',
                salary: null,
                office_time: '',
                skills: '',
                benefits: '',
            },
            categoryOptions: [],
            salaryTypeOptions: [],
            experienceLevelOptions: [],
            countryOptions: [],
            stateOptions: [],
            cityOptions: [],
            skillsOptions: [],
            benefitsOptions: [],
            employmentTypeOptions: [],
            isLoading: false,
            isPublished: false,
            showEditor: false,
            showCropperModel: false,
            coverPhoto: 'https://via.placeholder.com/1600x840.png',
            tempfile: null,
            uploadingImage: false,
            croppieUploadOption: {
                enableExif: true,
                quality: 1,
                // format: 'jpeg',
                size: {
                    width: null,
                    height: null
                },
            },
            croppieShowOption: {
                boundary: {
                    width: null,
                    height: null,
                },
                viewport: {
                    width: null,
                    height: null,
                }
            },
            jobDetailsTabs: [
                {
                    id: 1,
                    title: 'job details',
                    active: true,
                },
                {
                    id: 2,
                    title: 'Responsibilities',
                    active: false,
                }
            ],
            oldData: null,
            beforeRouteLeave: false,
            showJobTemplateSearch: false,
            showJobTempPreview:false,
            previewId:null,
            previewUrl:null,
            otherEmploymentType: false,
            employment_type_other: ''
        }
    },
    computed: {
        ...mapState(['company']),
        currentJobId() {
            return this.$route.params.id;
        },

        tomorrow() {
            return moment().add(1, 'days').format('MM/DD/YYYY');
        },

        activeTabId() {
            let tab = _.find(this.jobDetailsTabs, {active: true});
            if (tab) {
                return tab.id;
            } else {
                return 1;
            }

        },

        datePickerOptions() {
            return {
                format: 'MM/DD/YYYY',
                minDate: this.tomorrow,
                icons: datePickerIcons,
                useCurrent: false
            }
        },

        jobDetailEditorConfig() {
            let placeholderConfig = {'placeholder': this.$t(`Write job details here.`) };
            return {...ckEditorBasicOptions, ...placeholderConfig};
        },
        jobResponsibilityEditorConfig() {
            let placeholderConfig = {'placeholder': this.$t(`Write responsibilities here.`) };
            return {...ckEditorBasicOptions, ...placeholderConfig};
        },
        canPublishJob() {
            return canPublishJob();
        }
    },
    methods: {

        ...mapActions([LOAD_MESSAGE_NOTIFICATION]),
        showOtherOption(val) {
          if (val.id === 99) {
              this.otherEmploymentType = true;
              this.$nextTick(() => {
                  document.getElementById('custom_emp_type').focus();
              });
          } else {
            this.otherEmploymentType = false;
          }
        },
        async jobPreview() {
            await this.saveJobPreview();
            this.showJobTempPreview = true;
            const el = document.body;
            el.classList.add('modal-open');
        },
        hideJobPreview() {
            this.showJobTempPreview = false;
            const el = document.body;
            el.classList.remove('modal-open');

        },
        async saveJobPreview() {
            this.form.previewId = this.previewId;
            if (this.editMode) {
             this.form.job_post_id = this.currentJobId;
            }
            this.form.employment_type_other = this.form.employment_type?.id===99 ? this.employment_type_other : null;

            let {data:{data}} = await client().post(`/job/set-preview`, this.form);
            this.previewId = data.previewId;
            this.previewUrl = data.url;
        },
        onJDReady(editor) {
            const toolbarContainer = document.querySelector('#job-details');
            toolbarContainer.prepend(editor.ui.view.toolbar.element);
        },
        onJRReady(editor) {
            const toolbarContainer = document.querySelector('#job-responsibilities');
            toolbarContainer.prepend(editor.ui.view.toolbar.element);
        },
        showJobPostTemplates() {
            this.showJobTemplateSearch = true;
        },

        selectedTemplate(result) {
            let message = {
                title: this.$t(`Confirmation`),
                body: this.$t(`Are you sure you want to leave this page?`)
            }
            let formData = {...this.form};
            formData.expire_at = this.form.expire_at ? moment(this.form.expire_at).format('YYYY-DD-MM') : null;
            this.oldData.expire_at = this.oldData.expire_at ? moment(this.oldData.expire_at).format('YYYY-DD-MM') : null;
            if (!_.isEqual(this.oldData, formData)) {
                formData.expire_at = formData.expire_at ? moment(formData.expire_at) : null;
                this.$dialog.confirm(message).then(() => {
                    for (const [key, value] of Object.entries(result)) {
                        this.form[key] = value;
                    }
                });
            } else {
                for (const [key, value] of Object.entries(result)) {
                    this.form[key] = value;
                }
            }

            this.showOtherOption(result.employment_type);
            this.employment_type_other = '';
            this.showJobTemplateSearch = false;
        },
        isValid() {
            this.errors = {};
            if (this.form.title.length < 1) {
                this.errors.title = 'Please provide job title.';
                return false;
            }

            if (this.form.details.length < 1) {
                this.errors.details = 'Please provide job details.';

                return false;
            }

            if (this.form.category === '') {
                this.errors.category = 'Please select a category.';
                return false;
            }

            if (this.form.expire_at === '') {
                this.errors.expire_at = 'Please select an expiry date.';
                return false;
            }

            if (this.form.skills.length === 0) {
                this.errors.skills = 'Please select some skills.';
                return false;
            }

            if (this.form.employment_type && Object.keys(this.form.employment_type).length === 0) {
                this.errors.employment_type = 'Please select employment type.';
                return false;
            }

            if (this.form.salary_type == '') {
                this.errors.salary_type = 'Please select salary type.';
                return false;
            }

            if (this.form.employment_type?.id ===  99) {

                if (this.employment_type_other.length === 0) {
                    this.errors.employment_type = 'Please write custom employment type.';
                    return false;
                }

                if (this.employment_type_other.length > 50) {
                    this.errors.employment_type = "Maximum 50 characters are allowed.";
                    return false;
                }


                const duplicateEmployeeType = this.employmentTypeOptions.filter((item) => {
                    return item.name.toLowerCase() === this.employment_type_other.toLowerCase();
                });

                if(duplicateEmployeeType.length > 0) {
                    this.errors.employment_type = "This custom employment type already exits.";
                    return false;
                }
            }
            return true;
        },

        async saveJob() {
            if (!this.isValid()) {
                this.$toast.error(this.$t('Oops! Please check your input'));
                return false;
            }
            this.form.employment_type_other = this.form.employment_type?.id===99 ? this.employment_type_other : null;
            this.isLoading = true;
            try {
                this.errors = [];
                let {data} = await client().post(`/job/create`, this.form);
                if (data.status === 'SUCCESS') {
                    this.$toast.success(this.$t(data.message));
                    this.beforeRouteLeave = true;
                    if (this.company.package_rule.screening) {
                        await this.$router.push({name: 'job.screening', params: {id: data.data.slug}});
                    } else if (this.company.package_rule.quiz) {
                        await this.$router.push({name: 'job.quiz', params: {id: data.data.slug}});
                    } else {
                        await this.$router.push({name: 'job.edit', params: {id: data.data.slug}});
                        if(this.canPublishJob) {
                            EventBus.$emit(CHECK_AND_PUBLISH_JOB);
                        }
                    }
                    EventBus.$emit(UPDATE_JOB_INFO);
                    this[LOAD_MESSAGE_NOTIFICATION]();
                } else {
                    this.$toast.error(this.$t(data.message));
                }
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(err.response.data.message);
                }
            }
            this.isLoading = false;
        },

        async updateJob() {
            if (!this.isValid()) {
                this.$toast.error(this.$t('Oops! Please check your input'));
                return false;
            }

            this.form.employment_type_other = this.form.employment_type?.id===99 ? this.employment_type_other : null;

            this.isLoading = true;
            try {
                let {data} = await client().put(`/job/${this.currentJob.slug}/update`, this.form);
                if (data.status === 'SUCCESS') {
                    this.$toast.success(this.$t(data.message));
                    this.beforeRouteLeave = true;
                    if (this.company.package_rule.screening) {
                        await this.$router.push({name: 'job.screening', params: {id: data.data.slug}});
                    } else if (this.company.package_rule.quiz) {
                        await this.$router.push({name: 'job.quiz', params: {id: data.data.slug}});
                    } else {
                        await this.$router.push({name: 'job.edit', params: {id: data.data.slug}});
                        window.scrollTo(0, 0);
                        if(this.canPublishJob) {
                            EventBus.$emit(CHECK_AND_PUBLISH_JOB, data.data)
                        }
                    }
                    EventBus.$emit(UPDATE_JOB_INFO);
                    this[LOAD_MESSAGE_NOTIFICATION]();
                } else {
                    this.$toast.error(this.$t(data.message));
                }
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
            this.isLoading = false;
        },
        onEditorChange({quill, html, text}) {
            this.details = html;
        },

        findCategory(searchQuery = '', id = '') {
            getCategory(searchQuery).then((data) => {
                this.categoryOptions = data;
            });
        },

        getCategories: debounce(function (searchQuery) {
            this.findCategory(searchQuery);
        }, 1200),

        findCountry() {
            getCountry().then((data) => {
                this.countryOptions = data;
            });
        },

        findState() {

            if (!this.form.country) {
                this.$toast.error(this.$t('Please select country first'));
                return 0;
            }

            getState(this.form.country.id).then((data) => {
                this.stateOptions = data;
            });
        },

        findCity() {
            if (!this.form.country || !this.form.state) {
                this.$toast.error(this.$t('Please select country and state first'));
                return 0;
            }

            getCity(this.form.country.id, this.form.state.id).then((data) => {
                this.cityOptions = data;
            });
        },

        findSkills() {
            getSkill().then((data) => {
                this.skillsOptions = data;
            });
        },

        getJobCreateEnumData() {
            getJobCreateMetaData().then((data) => {
                if (data) {
                    this.employmentTypeOptions = data.employment_type;
                    this.salaryTypeOptions = data.salary_type;
                    this.experienceLevelOptions = data.experience_level;
                }
            });
        },
        findCurrentJobBasicData() {
            if (this.$route.params.id !== undefined) {
                this.editMode = true;
                getJobBasicData(this.$route.params.id).then((data) => {
                    this.currentJob = data;
                    this.form = this.currentJob;
                    this.oldData = {...this.form};
                    this.form.expire_at = moment(this.currentJob.expire_at);
                    this.isPublished = this.currentJob.status === JOB.STATUS.PUBLISHED;
                    this.showEditor = true;
                    this.coverPhoto = this.currentJob.banner_image;

                    if (data.employment_type?.id === 99) {
                        this.otherEmploymentType = true;
                        this.employment_type_other = this.form.employment_type_other;
                    }
                }).catch((error) => {
                    this.$toast.error(this.$t(error.response.data.message));
                    if (error.response.status === 480 || error.response.status === 490) {
                        this.$router.push({name: 'dashboard'});
                    }
                }).finally(() => {
                    this.$refs.job_title.focus();
                });
            } else {
                this.showEditor = true;
            }
        },

        croppie(target, e) {

            this.showCropperModal();

            this.target = target;

            var files = e.target.files || e.dataTransfer.files;

            if (!files.length) {
                this.closeCropperModal();
                return;
            }

            this.tempfile = files[0];


            let allowedFileExists = ['png', 'jpg', 'jpeg'];

            if (!allowedFileExists.includes(this.tempfile.name.split('.').pop().toLowerCase())) {
                this.closeCropperModal();
                this.$toast.error(this.$t(`Invalid image format.`));
                return;
            }

            var reader = new FileReader();

            reader.onload = e => {
                this.$refs.croppieRef.bind({
                    url: e.target.result
                });
            };

            reader.readAsDataURL(this.tempfile);
        },

        async cropImage() {

            let croppedImage = this.$refs.croppieRef;

            croppedImage.result(this.croppieUploadOption, base64 => {
                this.form.coverPhoto = base64;
                fetch(base64).then(e => e.blob()).then((blob) => {

                    const formData = new FormData();

                    formData.append('file', blob, this.target + '.' + this.tempfile.name.split(".").pop());
                    formData.append('target', this.target);
                    formData.append('id', this.currentJobId);

                    this.uploadingImage = true;

                    if (this.currentJobId) {
                        client()
                            .post(`/job/image`, formData)
                            .then(response => {
                                return this.$toast.success(this.$t(response.data.message));
                            })
                            .catch(error => {
                                return this.showErrorText(error)
                            });
                    }

                    this.setUploadedImage(this.target, base64);

                    this.uploadingImage = false;

                    this.closeCropperModal();
                })
            });
        },

        setCroppieConfig(vWidth, vHeight, width, height) {
            this.croppieUploadOption.size.width = width;
            this.croppieUploadOption.size.height = height;
            this.croppieShowOption.viewport.width = vWidth;
            this.croppieShowOption.viewport.height = vHeight;
            this.croppieShowOption.boundary.width = vWidth + 100;
            this.croppieShowOption.boundary.height = vHeight + 100;

        },
        showCropperModal() {
            this.showCropperModel = true;
            const el = document.body;
            el.classList.add('modal-open');
        },

        closeCropperModal() {
            document.getElementById('cover_photo').value = null;
            this.showCropperModel = false;
            this.fade = false;
            const el = document.body;
            el.classList.remove('modal-open');
        },

        setUploadedImage(target, base64formate) {
            if (target === 'cover_photo') {
                this.coverPhoto = base64formate;
            }
        },

        showErrorText(error) {
            if (typeof error == 'string') {
                return this.$toast.error(this.$t(error));
            } else if (error.response !== undefined && error.response.status === 422) {/* validation error */

                if (typeof error.response.data.message == 'string') {

                    return this.$toast.error(error.response.data.message);/* System error */
                }
                this.errors = error.response.data.message;
                return this.$toast.error(this.$t('Oops! Please check your input'));
            } else {
                // this.$toast.error(this.$t('Oops! Somethings was wrong'));
            }
        },

        switchTab(tab) {
            this.jobDetailsTabs = _.map(this.jobDetailsTabs, t => {
                if (t.id === tab.id) {
                    t.active = true;
                } else {
                    t.active = false;
                }
                return t;
            });

        },
    },
    async created() {
        this.oldData = {...this.form};
        await this.getJobCreateEnumData();
        await this.findCurrentJobBasicData();
        this.coverPhoto = this.company.companyCoverImage;
        await this.$refs.job_title.focus();
        EventBus.$on(SHOW_JOB_TEMP_PREVIEW, () => {
            this.jobPreview();
        })
    },
    beforeRouteLeave(to, from, next) {
        if (this.beforeRouteLeave) {
            return next();
        }
        let message = {
            title: this.$t(`Confirmation`),
            body: this.$t(`Are you sure you want to leave this page?`)
        }
        let formData = {...this.form};
        formData.expire_at = this.form.expire_at ? moment(this.form.expire_at).format('YYYY-DD-MM') : null;
        this.oldData.expire_at = this.oldData.expire_at ? moment(this.oldData.expire_at).format('YYYY-DD-MM') : null;
        if (!_.isEqual(this.oldData, formData)) {
            formData.expire_at = formData.expire_at ? moment(formData.expire_at) : null;
            this.$dialog.confirm(message).then(() => next());
            return next(false);
        }
        return next();
    }
}
</script>

<style scoped>

.custom-control.custom-switch .custom-control-input:checked + .custom-control-label:before {
    color: #fff;
    background: #60ce83;
    border-color: #60ce83;
}

.custom-control-label {
    cursor: pointer;
}

.custom-switch .custom-control-label::before {
    width: 2.5rem;
    height: 1.5rem;
    border-radius: 2rem;
    left: -3.4rem;
}

.custom-control.custom-switch {
    padding-left: 3.2rem;
}

.custom-switch .custom-control-label::after {
    left: calc(-3rem - 4px);
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    border-radius: 2rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(0.90rem);
}

label.custom-control-label {
    line-height: 2rem;
    margin-bottom: 0 !important;
}

.nav-pills .nav-link.active {
    background: #587dfc;
}


input.notify[type=checkbox] {
    display: none;
}

input.notify + label {
    cursor: pointer;
    width: 64px;
    height: 30px;
    background: #60CE83 !important;
    display: inline-block;
    border-radius: 100px;
    position: relative;
    margin: auto;
}

input.notify + label:after {
    content: '';
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

input.notify:checked + label {
    background: #FF5F74 !important;
}

input.notify + label > span {
    color: #fff;
    text-transform: uppercase;
    float: left;
    padding: 8px 8px;
    font-size: .9rem;
    line-height: 1;
}

input.notify:checked + label > span {
    float: right;
}

.power-by__remove {
    height: 30px !important;
    margin: unset !important;
}

@media all and (max-width: 1399px) {
    .form-group .company-powered_by .selection-box label:not(.checkbox):not(.radio):not(.file-browse-button):not(.brand--color) {
        height: 30px !important;
    }
}
@media all and (max-width: 1599px) {
    input.notify + label > span {
        padding: 10px 8px;
    }
}

#remove-company-cover-photo {
    position: absolute;
    top: 30px;
    left: 30px;
    display: flex;
    align-items: center;
}

#remove-company-cover-photo .selection-box {
    font-size: 0;
}

#remove-company-cover-photo .selection-box .power-by__remove {
    margin-bottom: 0 !important;
    padding-right: 25px !important;
    padding-left: 5px !important;
    width: auto !important;
    transition: padding .3s ease;
}

#remove-company-cover-photo .selection-box input:checked + .power-by__remove {
    padding-right: 5px !important;
    padding-left: 25px !important;
}
#remove-company-cover-photo .selection-box input:checked + .power-by__remove:after {
    transition: all .3s ease;
}

input.notify:checked + label:after {
    right: calc(100% - 25px);
}

</style>
